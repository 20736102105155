import { Component, OnInit, OnDestroy } from '@angular/core';
import { NewService } from '../../services/new.service';
import { Router } from '@angular/router';
import { SliderService } from '../../services/slider.service';

declare var $: any;
declare var FullCalendar: any;
declare var Swiper: any;
declare var swal: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  interval: any;
  calendarElem: any;
  calendar: any;
  calendarPlugins = ['dayGrid'];
  calendarEvents: any[] = [
    /*{
      title: 'Business Lunch',
      start: '2019-08-03T13:00:00',
      constraint: 'businessHours'
    },
    {
      title: 'Meeting',
      start: '2019-08-13T11:00:00',
      constraint: 'availableForMeeting', // defined below
      color: '#257e4a'
    },
    {
      title: 'Conference',
      start: '2019-08-18',
      end: '2019-08-20'
    },
    {
      title: 'Party',
      start: '2019-08-29T20:00:00'
    },

    // areas where "Meeting" must be dropped
    {
      groupId: 'availableForMeeting',
      start: '2019-08-11T10:00:00',
      end: '2019-08-11T16:00:00',
      rendering: 'background'
    },
    {
      groupId: 'availableForMeeting',
      start: '2019-08-13T10:00:00',
      end: '2019-08-13T16:00:00',
      rendering: 'background'
    },

    // red areas where no events can be dropped
    {
      start: '2019-08-24',
      end: '2019-08-28',
      overlap: false,
      rendering: 'background',
      color: '#ff9f89'
    },
    {
      start: '2019-08-06',
      end: '2019-08-08',
      overlap: false,
      rendering: 'background',
      color: '#ff9f89'
    }*/
  ];

  newList: any [] = [];
  sliderList: any [] = [];
  page: number = 1;
  constructor(private _newService: NewService, private _sliderService: SliderService, private _router: Router) {
    this.loadAllNews();
    this.loadAllBanners();
   }

   loadAllBanners(){
    this.sliderList = [];
    this._sliderService.getAllSliders().subscribe((result)=>{
      this.sliderList = result.json().data;
      setTimeout(() => {
        if(this.sliderList.length > 0){
          var swiper = new Swiper('.swiper-container', {
            spaceBetween: 30,
            centeredSlides: true,
            loop: true,
            lazy: true,
            autoHeight: true,
            autoplay: {
              delay: 3000
            },
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
          });
        }
      }, 1000);
    },
    error => {
      if(error.status == 401){
      }
      if(error.json() || error.json().type == "error"){
        let errorMessage = "Ocurrió un error al ejecutar ésta acción. Por favor verifique";
        if(error.json().message != undefined){
          errorMessage = error.json().message;
        }
        swal('Señor Usuario', errorMessage, 'error');
      }
    });
  }
  
   loadAllNews(){
    this.newList = [];
    this._newService.getAllNews().subscribe((result)=>{
      this.newList = result.json().data.news;
    },
    error => {
      if(error.status == 401){
      }
      if(error.json() || error.json().type == "error"){
        let errorMessage = "Ocurrió un error al ejecutar ésta acción. Por favor verifique";
        if(error.json().message != undefined){
          errorMessage = error.json().message;
        }
        swal('Señor Usuario', errorMessage, 'error');
      }
    });
  }

  ngOnInit() {
    this.initCalendar();
  }

  initCalendar() {
    setTimeout(() => {
      var self = this;
        this.calendarElem = $('#calendar');
        this.calendar = new FullCalendar.Calendar(this.calendarElem[0], {
          plugins: [ 'interaction', 'dayGrid', 'timeGrid', 'list' ],
          locale: 'es',
          header: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridDay,listMonth'
          },
          navLinks: true, // can click day/week names to navigate views
          businessHours: true, // display business hours
          editable: true,
          events: this.calendarEvents
        });

        this.calendar.render();
        this.calendar.rerenderEvents();
    }, 500);
  }

  goToNew(data: any){
    this._router.navigate(['/noticia',data.ruta]);
  }

  ngOnDestroy() {
    clearInterval(this.interval)
  }

}
