import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ContactService } from '../../services/contact.service';
import { Router } from '@angular/router';

declare var $: any;
declare var swal: any;
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  forma:FormGroup;
  constructor(private _contactService: ContactService, private _router: Router) { 
    this.formInit();
  }

  ngOnInit() {
    $('#textarea1').trigger('autoresize');
  }

  resetForm() {
    let empty = {
      ContactId: 0,
      Name: '',
      LastName: '',
      Email: '',
      Message: ''
    };

    this.forma.setValue(empty);
  }

  formInit() {
    this.forma = new FormGroup({
      'ContactId': new FormControl(0),
      'Name': new FormControl('', Validators.required),
      'LastName': new FormControl('', Validators.required),
      'Email': new FormControl('', Validators.required),
      'Message': new FormControl('')
    });
  }

  saveChanges() {
    this._contactService.saveData(this.forma.value)
    .subscribe(result => {
      if(result.json().success == true){
        swal('Atención', 'Proceso realizado correctamente', 'success');
        this.resetForm();
      }else{
        swal('Atención', result.json().message, 'warning');
      }
    },
    error => {
      $('body').loading('stop');
      swal('Señor Usuario', 'Se ha presentado un error en el sistema. Por favor comuniquese con el administrador', 'error');
    });
  }

}
