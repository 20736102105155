import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GradeService } from '../../services/grade.service';

declare var $: any;
declare var swal: any;
@Component({
  selector: 'app-aspirante-grado',
  templateUrl: './aspirante-grado.component.html',
  styleUrls: ['./aspirante-grado.component.css']
})
export class AspiranteGradoComponent implements OnInit {
  data: any;

  forma:FormGroup;
  constructor(private gradeService: GradeService) { 
    this.getData();
    this.formInit();
  }

  ngOnInit() {
    setTimeout(() => {
      $('select').formSelect();
    }, 1000);
  }

  formInit() {
    this.forma = new FormGroup({
      'CandidateId': new FormControl(0),
      'Name': new FormControl('', Validators.required),
      'DocumentType': new FormControl('', Validators.required),
      'Document': new FormControl('', Validators.required),
      'Email': new FormControl(''),
      'Phone': new FormControl('', Validators.required),
      'Address': new FormControl('')
    });
  }

  resetForm() {
    let empty = {
      CandidateId: 0,
      Name: '',
      DocumentType: '',
      Document: '',
      Email: '',
      Phone: '',
      Address: ''
    };

    this.forma.setValue(empty);
  }

  getData() {
    this.gradeService.getPageData().subscribe((result)=> {
      if(result.json()){
        let data = result.json().data;
        if(data && data.length > 0) {
          this.data = data[0];
          this.data.configPage = atob(this.data.configPage);
        }
      }
    });
  }

  saveChanges() {
    this.gradeService.saveData(this.forma.value)
    .subscribe(result => {
      if(result.json().success == true){
        swal('Atención', 'Proceso realizado correctamente', 'success');
        this.resetForm();
      }else{
        swal('Atención', result.json().message, 'warning');
      }
    },
    error => {
      $('body').loading('stop');
      swal('Señor Usuario', 'Se ha presentado un error en el sistema. Por favor comuniquese con el administrador', 'error');
    });
  }

}
