import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-general',
  templateUrl: './contact-general.component.html',
  styleUrls: ['./contact-general.component.css']
})
export class ContactGeneralComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
