import { Component, OnInit } from '@angular/core';

declare var $: any;
declare var ElastiStack: any;
declare var Swiper: any;
@Component({
  selector: 'app-emprendimiento',
  templateUrl: './emprendimiento.component.html',
  styleUrls: ['./emprendimiento.component.css']
})
export class EmprendimientoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    //new ElastiStack( document.getElementById( 'elasticstack' ) );

    setTimeout(() => {
      var swiper = new Swiper('.swiper-container', {
        spaceBetween: 30,
        centeredSlides: true,
        loop: true,
        lazy: true,
        autoHeight: true,
        autoplay: {
          delay: 3000
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    }, 1000);
  }

}
