import { Component, OnInit } from '@angular/core';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BannerService } from './services/banner.service';

declare var $: any;
declare var swal: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = '';
  modal: any;
  bannerData: any;
  constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject(APP_ID) private appId: string, private _bannerService: BannerService) {}

  onActivate(event: any) {
    if (isPlatformBrowser(this.platformId)) { }
  }

  ngOnInit() {
    this.loadAllBanners();
  }

  loadAllBanners(){
    this._bannerService.getAllBanners().subscribe((result)=>{
      let data = result.json().data;
      if(data) {
        if(data.length > 0){
          data.forEach((item)=>{
            if(item.activeBanner == 1) {
              this.bannerData = item;
            }
          });
          setTimeout(() => {
            $('#modal1').modal();
            $('#modal1').modal('open');
          }, 500);
        }
      }
    },
    error => {
      if(error.status == 401){
      }
      if(error.json() || error.json().type == "error"){
        let errorMessage = "Ocurrió un error al ejecutar ésta acción. Por favor verifique";
        if(error.json().message != undefined){
          errorMessage = error.json().message;
        }
        swal('Señor Usuario', errorMessage, 'error');
      }
    });
  }
}
