import { Component, OnInit } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  programas: any [] = [
    {
      id: 1,
      name: 'Técnico Laboral en Administración y Contabilidad',
      path: ''
    },
    {
      id: 2,
      name: 'Tecnico Laboral en Mercadeo y Ventas',
      path: ''
    },
    {
      id: 3,
      name: 'Técnico Laboral en Entrenamiento Deportivo',
      path: ''
    },
    {
      id: 4,
      name: 'Técnico Laboral en Sistemas',
      path: ''
    },
    {
      id: 5,
      name: 'Técnico Laboral en Estilista Integral',
      path: ''
    },
    {
      id: 6,
      name: 'Técnico Laboral en Atención Integral a la Primera Infancia',
      path: ''
    },
    {
      id: 7,
      name: 'Técnico Laboral en Construcción de Edificaciones',
      path: ''
    },
    {
      id: 8,
      name: 'Técnico Laboral en Construción de Vías',
      path: ''
    }
  ];

  cursos: any [] = [
    {
      id: 1,
      name: 'Curso de Inglés (Ejemplo)',
      path: ''
    },
    {
      id: 1,
      name: 'Curso de Repostería (Ejemplo)',
      path: ''
    }
  ];
  constructor() { }

  ngOnInit() {
    $('.sidenav').sidenav();
    $('.dropdown-trigger').dropdown();
  }

  onKey(event: any) {
    console.log(event.target.value);
  }

  goToPrograma(program: any) {

  }

  goToCurso(curso: any) {

  }

  goToUrl() {
    let link = 'centrotecnologico.q10academico.com';
    let url: string = '';
    if (!/^http[s]?:\/\//.test(link)) {
      url += 'http://';
    }

    url += link;
    window.open(url, '_blank');
  }


}
