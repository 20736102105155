import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InternationalizationService } from '../../services/internationalization.service';

declare var $: any;
@Component({
  selector: 'app-internacionalizacion',
  templateUrl: './internacionalizacion.component.html',
  styleUrls: ['./internacionalizacion.component.css']
})
export class InternacionalizacionComponent implements OnInit {
  data: any;
  constructor(private _activatedRoute: ActivatedRoute, private _interService: InternationalizationService) {
    this._interService.getInterData().subscribe((result)=> {
      if(result.json()){
        this.data = result.json().data;
        this.data.content = atob(this.data.content);
      }
    });
  }

  ngOnInit() {
  }
}
