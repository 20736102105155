import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { NgxPaginationModule } from 'ngx-pagination';


import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { APP_ROUTING } from './app.routes';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { ProgramsComponent } from './components/programs/programs.component';
import { ProgramComponent } from './components/program/program.component';
import { MisionComponent } from './components/mision/mision.component';
import { VisionComponent } from './components/vision/vision.component';
import { ObjectivesComponent } from './components/objectives/objectives.component';
import { DirectoryComponent } from './components/directory/directory.component';
import { PorQueCtcComponent } from './components/por-que-ctc/por-que-ctc.component';
import { PreinscripcionComponent } from './components/preinscripcion/preinscripcion.component';
import { EmprendimientoComponent } from './components/emprendimiento/emprendimiento.component';
import { ReglamentoComponent } from './components/reglamento/reglamento.component';
import { FormacionInglesComponent } from './components/formacion-ingles/formacion-ingles.component';
import { ConveniosComponent } from './components/convenios/convenios.component';
import { GaleriaComponent } from './components/galeria/galeria.component';
import { ActualizarDatosComponent } from './components/actualizar-datos/actualizar-datos.component';
import { CursosComponent } from './components/cursos/cursos.component';
import { CursoComponent } from './components/curso/curso.component';
import { AccesoInformacionComponent } from './components/acceso-informacion/acceso-informacion.component';
import { InternacionalizacionComponent } from './components/internacionalizacion/internacionalizacion.component';
import { PqrsfComponent } from './components/pqrsf/pqrsf.component';
import { ConfigService } from './services/config.service';
import { LocalStorageService } from './services/local-storage.service';
import { PqrdsService } from './services/pqrds.service';
import { ContactComponent } from './components/contact/contact.component';
import { ContactService } from './services/contact.service';
import { DefaultNewPipe } from './pipes/default-new.pipe';
import { NoticiaComponent } from './components/noticia/noticia.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { DirectoryService } from './services/directory.service';
import { DefaultProfilePipe } from './pipes/default-profile.pipe';
import { TerminosComponent } from './components/terminos/terminos.component';
import { AspiranteGradoComponent } from './components/aspirante-grado/aspirante-grado.component';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';
import { ContactGeneralComponent } from './components/contact-general/contact-general.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ProgramsComponent,
    ProgramComponent,
    MisionComponent,
    VisionComponent,
    ObjectivesComponent,
    DirectoryComponent,
    PorQueCtcComponent,
    PreinscripcionComponent,
    EmprendimientoComponent,
    ReglamentoComponent,
    FormacionInglesComponent,
    ConveniosComponent,
    GaleriaComponent,
    ActualizarDatosComponent,
    CursosComponent,
    CursoComponent,
    AccesoInformacionComponent,
    InternacionalizacionComponent,
    PqrsfComponent,
    ContactComponent,
    DefaultNewPipe,
    SafeHtmlPipe,
    NoticiaComponent,
    DefaultProfilePipe,
    TerminosComponent,
    AspiranteGradoComponent,
    ContactInfoComponent,
    ContactGeneralComponent
  ],
  imports: [
    BrowserModule,
    HttpModule,
    APP_ROUTING,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ],
  providers: [ ConfigService, LocalStorageService, PqrdsService, ContactService, DirectoryService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
