import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PqrdsService } from '../../services/pqrds.service';
import { Router } from '@angular/router';

declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-pqrsf',
  templateUrl: './pqrsf.component.html',
  styleUrls: ['./pqrsf.component.css']
})
export class PqrsfComponent implements OnInit {
  forma:FormGroup;

  newFile: File;
  newImageLoaded: boolean = false;
  newsImageList: any [] = [];
  constructor(private _pqrdfService: PqrdsService, private _router: Router) { 
    this.formInit();
  }

  ngOnInit() {
    $('select').formSelect();
    $('#textarea1').trigger('autoresize');
  }

  resetForm() {
    let empty = {
      PqrdfId: 0,
      Name: '',
      LastName: '',
      DocumentType: 'Cédula de Ciudadanía',
      Document: '',
      Email: '',
      Subject: '',
      Message: '',
      Terms: false
    };

    this.forma.setValue(empty);
    this.newsImageList = [];
  }

  formInit() {
    this.forma = new FormGroup({
      'PqrdfId': new FormControl(0),
      'Name': new FormControl('', Validators.required),
      'LastName': new FormControl('', Validators.required),
      'DocumentType': new FormControl('Cédula de Ciudadanía', Validators.required),
      'Document': new FormControl('', Validators.required),
      'Email': new FormControl('', Validators.required),
      'Subject': new FormControl(''),
      'Message': new FormControl(''),
      'Terms': new FormControl(false, Validators.required)
    });
  }

  saveChanges() {
    $('body').loading({
      theme: 'light',
      message: 'POR FAVOR ESPERE...'
    });
    this._pqrdfService.saveData(this.forma.value)
    .subscribe(result => {
      $('body').loading('stop');
      if(result.json().success == true){
        let data = result.json().data;
        this.forma.controls['PqrdfId'].setValue(data.pqrdfId);
        swal('Atención', 'La solicitúd ha sido recibida. Por favor cargue los archivos de soporte o por el contrario haga clic en finalizar.', 'success');
      }else{
        swal('Atención', result.json().message, 'warning');
      }
    },
    error => {
      $('body').loading('stop');
      swal('Señor Usuario', 'Se ha presentado un error en el sistema. Por favor comuniquese con el administrador', 'error');
    });
  }

  loadFile(event){
    this.readURL(event.target);
  }

  readURL(input) {
    let reader = new FileReader();
    if (input.files && input.files[0]) {
      this.newFile = input.files[0];
      reader.readAsDataURL(this.newFile);
      reader.onload = (e:any) => {
        //$('#picturePreview').attr('src', e.target.result).fadeIn('slow');
        this.newImageLoaded = true;
        this.uploadFile();
      };
    }
  }

  uploadFile(){
    if(this.forma.value.PqrdfId == 0) {
      swal('Señor Usuario', 'Debe crear primero la solicitud.', 'warning');
      return false;
    }

    $('body').loading({
      theme: 'light',
      message: 'POR FAVOR ESPERE...'
    });
    this._pqrdfService.uploadFile(this.newFile, 'requests', 'Solicitud', this.forma.value.PqrdfId).subscribe((resp)=>{
      $('body').loading('stop');
      if(resp.json()){
        if(resp.json().success == false){
          swal('Señor Usuario', resp.json().message, 'warning');
        }else{
          this.newsImageList = resp.json().data;
          setTimeout(() => {
            swal('Atención', 'Archivo cargado exitosamente.', 'success');
          }, 500);
        }
      }
    },
    error => {
      $('body').loading('stop');
      if(error.status == 401){
        swal('Señor Usuario', 'No tiene permisos para ejecutar ésta acción.', 'error');
        this._router.navigate(['login']);
      }
      if(error.json() || error.json().type == "error"){
        let errorMessage = "Ocurrió un error al ejecutar ésta acción. Por favor verifique";
        if(error.json().message != undefined){
          errorMessage = error.json().message;
        }
        swal('Señor Usuario', errorMessage, 'error');
      }
    });
  }

  finalizar() {
    swal('Atención', 'El proceso ha finalizado exitosamente.', 'success');
    this.resetForm();
  }
}
