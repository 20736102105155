import { Component, OnInit } from '@angular/core';
import { ProgramService } from '../../services/program.service';

declare var $: any;
declare var swal: any;
@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.css']
})
export class ProgramsComponent implements OnInit {
  programList: any [] = [];
  constructor(private _programService: ProgramService) { 
    this.loadAllPrograms();
  }

  ngOnInit() {
  }

  loadAllPrograms(){
    this.programList = [];
    this._programService.getAllPrograms().subscribe((result)=>{
      this.programList = result.json().data;
      console.log(this.programList);
    },
    error => {
      if(error.status == 401){
      }
      if(error.json() || error.json().type == "error"){
        let errorMessage = "Ocurrió un error al ejecutar ésta acción. Por favor verifique";
        if(error.json().message != undefined){
          errorMessage = error.json().message;
        }
        swal('Señor Usuario', errorMessage, 'error');
      }
    });
  }

}
