import { Injectable } from '@angular/core';
import { Http, Headers, Request, RequestMethod, RequestOptions } from '@angular/http';
import { LocalStorageService } from './local-storage.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class PqrdsService {
  baseUrl: string = '';
  public loggedIn = false;
  userProfile: any;
  tokenData: any;
  constructor(private _localStorage: LocalStorageService, private _configService: ConfigService, private _http: Http) { 
    this.loggedIn = !!this._localStorage.get('auth-data');
    this.baseUrl = this._configService.getApiURI();
  }

  getAllRequests() {
    this.userProfile = this._localStorage.getParsedObject("auth-data");
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this._http.get(this.baseUrl + '/pqrdf', { headers });
  }

  getRequest(id: number) {
    this.userProfile = this._localStorage.getParsedObject("auth-data");
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this._http.get(this.baseUrl + '/pqrdf/' + id, { headers });
  }

  saveData(data: any){
    this.userProfile = this._localStorage.getParsedObject("auth-data");

    let PqrdfId = data.PqrdfId;
    let Name = data.Name;
    let DocumentType = data.DocumentType;
    let Document = data.Document;
    let LastName = data.LastName;
    let Email = data.Email;
    let Subject = data.Subject;
    let Message = data.Message;
    let Active = 1;
    
    let body = JSON.stringify({ PqrdfId, Name, DocumentType, Document, LastName, Email, Subject, Message, Active });

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this._http.post(this.baseUrl + "/pqrdf", body, options);
  }

  updateData(data: any){
    this.userProfile = this._localStorage.getParsedObject("auth-data");

    let PqrdfId = data.PqrdfId;
    let Name = data.Name;
    let DocumentType = data.DocumentType;
    let Document = data.Document;
    let LastName = data.LastName;
    let Email = data.Email;
    let Subject = data.Subject;
    let Message = data.Message;
    let Active = 1;
    
    let body = JSON.stringify({ PqrdfId, Name, DocumentType, Document, LastName, Email, Subject, Message, Active });

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this._http.put(this.baseUrl + "/pqrdf/" + PqrdfId, body, options);
  }

  deleteData(requestId: number){
    this.userProfile = this._localStorage.getParsedObject("auth-data");

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this._http.delete(this.baseUrl + '/pqrdf/'+ requestId, { headers });
  }

  uploadFile( file: File,type: string, mode: string, id: any ) {
    this.userProfile = this._localStorage.getParsedObject("auth-data");

    let headers = new Headers();
    const formData = new FormData();
    formData.append(file.name, file);
    formData.append('type', type);
    formData.append('id', id);
    formData.append('mode', mode);

    const uploadReq = new Request({
      method: RequestMethod.Post,
      url: this.baseUrl + "/pqrdf/UploadFile",
      body: formData,
      headers: headers
    });

    return this._http.request(uploadReq);
  }

  deleteImage(requestId: number, urlFile: string){
    this.userProfile = this._localStorage.getParsedObject("auth-data");

    let PqrdfId = requestId;
    let UrlFile = urlFile;

    let body = JSON.stringify({PqrdfId, UrlFile});
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this._http.post(this.baseUrl + '/pqrdf/deleteFile', body, { headers });
  }
}