import { Component, OnInit } from '@angular/core';
import { DirectoryService } from '../../services/directory.service';

declare var $: any;
declare var swal: any;
@Component({
  selector: 'app-directory',
  templateUrl: './directory.component.html',
  styleUrls: ['./directory.component.css']
})
export class DirectoryComponent implements OnInit {
  funcionarioList: any [] = [];
  constructor(private _directoryService: DirectoryService) { 
    this.loadAllFuncionarios();
  }

  ngOnInit() {
  }

  loadAllFuncionarios(){
    this.funcionarioList = [];
    this._directoryService.getAllFuncionarios().subscribe((result)=>{
      this.funcionarioList = result.json().data;
    },
    error => {
      if(error.status == 401){
      }
      if(error.json() || error.json().type == "error"){
        let errorMessage = "Ocurrió un error al ejecutar ésta acción. Por favor verifique";
        if(error.json().message != undefined){
          errorMessage = error.json().message;
        }
        swal('Señor Usuario', errorMessage, 'error');
      }
    });
  }
}
