import { Component, OnInit } from '@angular/core';
import { ProgramService } from 'src/app/services/program.service';

declare var $: any;
declare var swal: any;
@Component({
  selector: 'app-cursos',
  templateUrl: './cursos.component.html',
  styleUrls: ['./cursos.component.css']
})
export class CursosComponent implements OnInit {
  coursesList: any [] = [];
  constructor(private _programService: ProgramService) { 
    this.loadAllCourses();
  }

  ngOnInit() {
  }

  loadAllCourses(){
    this.coursesList = [];
    this._programService.getAllCourses().subscribe((result)=>{
      this.coursesList = result.json().data;
      console.log(this.coursesList);
    },
    error => {
      if(error.status == 401){
      }
      if(error.json() || error.json().type == "error"){
        let errorMessage = "Ocurrió un error al ejecutar ésta acción. Por favor verifique";
        if(error.json().message != undefined){
          errorMessage = error.json().message;
        }
        swal('Señor Usuario', errorMessage, 'error');
      }
    });
  }

}
