import { Injectable } from '@angular/core';
import { Http, Headers, Request, RequestMethod, RequestOptions } from '@angular/http';
import { LocalStorageService } from './local-storage.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class NewService {
  baseUrl: string = '';
  public loggedIn = false;
  userProfile: any;
  tokenData: any;
  constructor(private _localStorage: LocalStorageService, private _configService: ConfigService, private _http: Http) { 
    this.loggedIn = !!this._localStorage.get('auth-data');
    this.baseUrl = this._configService.getApiURI();
  }

  getAllNews() {
    this.userProfile = this._localStorage.getParsedObject("auth-data");
    let headers = new Headers();
    let body = JSON.stringify({ });
    headers.append('Content-Type', 'application/json');
    return this._http.post(this.baseUrl + '/news/getPublic', body, { headers });
  }

  getNewInfo(id: string) {
    let Ruta = id;
    let body = JSON.stringify({ Ruta });
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this._http.post(this.baseUrl + '/news/getPublicNew', body, { headers });
  }
}
