import { Injectable } from '@angular/core';
import { Http, Headers, Request, RequestMethod, RequestOptions } from '@angular/http';
import { LocalStorageService } from './local-storage.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ProgramService {
  baseUrl: string = '';
  userProfile: any;
  constructor(private _configService: ConfigService, private _localStorage: LocalStorageService, private _http: Http) { 
    this.baseUrl = this._configService.getApiURI();
  }

  getAllPrograms() {
    this.userProfile = this._localStorage.getParsedObject("auth-data");
    let headers = new Headers();
    let body = JSON.stringify({ });
    headers.append('Content-Type', 'application/json');
    return this._http.post(this.baseUrl + '/programs/getPublic', body, { headers });
  }

  getAllCourses() {
    this.userProfile = this._localStorage.getParsedObject("auth-data");
    let headers = new Headers();
    let body = JSON.stringify({ });
    headers.append('Content-Type', 'application/json');
    return this._http.post(this.baseUrl + '/courses/getPublic', body, { headers });
  }
}
