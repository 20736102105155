import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-por-que-ctc',
  templateUrl: './por-que-ctc.component.html',
  styleUrls: ['./por-que-ctc.component.css']
})
export class PorQueCtcComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
