import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewService } from '../../services/new.service';

declare var $: any;
@Component({
  selector: 'app-noticia',
  templateUrl: './noticia.component.html',
  styleUrls: ['./noticia.component.css']
})
export class NoticiaComponent implements OnInit {
  newData: any;
  newsImageList: any [] = [];
  constructor(private _activatedRoute: ActivatedRoute, private _noticiasService: NewService) {
    this._activatedRoute.params.subscribe((params)=>{
      let newRuta: string = params['id'];
      this._noticiasService.getNewInfo(newRuta).subscribe((result)=> {
        if(result.json()){
          let data = result.json().data;
          data.contenido = atob(data.contenido),
          this.newsImageList = data.fotos;
          this.newData = data;
          $('.carousel.carousel-slider').carousel({
            fullWidth: true
          });
          setTimeout(()=>{
            this.loadPopupPlugin();
          },1000);
        }
      });
    });
  }
  
  ngOnInit() {
  }

  loadPopupPlugin() {
    $('.image-popup-vertical-fit').magnificPopup({
      type: 'image',
      closeOnContentClick: true,
      mainClass: 'mfp-img-mobile',
      image: {
          verticalFit: true
      }

  });

  $('.image-popup-fit-width').magnificPopup({
      type: 'image',
      closeOnContentClick: true,
      image: {
          verticalFit: false
      }
  });

  $('.image-popup-no-margins').magnificPopup({
      type: 'image',
      closeOnContentClick: true,
      closeBtnInside: false,
      fixedContentPos: true,
      mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
      image: {
          verticalFit: true
      },
      zoom: {
          enabled: true,
          duration: 300 // don't foget to change the duration also in CSS
      }
  });

  $('.popup-gallery').magnificPopup({
      delegate: 'a',
      type: 'image',
      tLoading: 'Loading image #%curr%...',
      mainClass: 'mfp-img-mobile',
      gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
      },
      image: {
          tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
          titleSrc: function(item) {
              return item.el.attr('title') + '<small>by Marsel Van Oosten</small>';
          }
      }
  });

  $('.zoom-gallery').magnificPopup({
      delegate: 'a',
      type: 'image',
      closeOnContentClick: false,
      closeBtnInside: false,
      mainClass: 'mfp-with-zoom mfp-img-mobile',
      image: {
          verticalFit: true,
          titleSrc: function(item) {
              return item.el.attr('title') + ' &middot; <a class="image-source-link" href="' + item.el.attr('data-source') + '" target="_blank">image source</a>';
          }
      },
      gallery: {
          enabled: true
      },
      zoom: {
          enabled: true,
          duration: 300, // don't foget to change the duration also in CSS
          opener: function(element) {
              return element.find('img');
          }
      }

  });

  $('#image-popups').magnificPopup({
      delegate: 'a',
      type: 'image',
      removalDelay: 500, //delay removal by X to allow out-animation
      callbacks: {
          beforeOpen: function() {
              // just a hack that adds mfp-anim class to markup
              this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
              this.st.mainClass = this.st.el.attr('data-effect');
          }
      },
      closeOnContentClick: true,
      midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
  });

  $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({

      disableOn: 700,
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,

      fixedContentPos: false
  });
  $('.popup-with-form').magnificPopup({
      type: 'inline',
      preloader: false,
      focus: '#name',

      // When elemened is focused, some mobile browsers in some cases zoom in
      // It looks not nice, so we disable it:
      callbacks: {
          beforeOpen: function() {
              if ($(window).width() < 700) {
                  this.st.focus = false;
              } else {
                  this.st.focus = '#name';
              }
          }
      }
  });

  $('.simple-ajax-popup-align-top').magnificPopup({
      type: 'ajax',
      alignTop: true,
      overflowY: 'scroll' // as we know that popup content is tall we set scroll overflow by default to avoid jump
  });

  $('.simple-ajax-popup').magnificPopup({
      type: 'ajax'
  });
  }
}
