import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'defaultProfile'
})
export class DefaultProfilePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let newPhoto = "assets/imgs/avatar.jpg";

    if( !value ){
      return newPhoto;
    }


    return ( value.length > 0 ) ? value : newPhoto;
  }

}