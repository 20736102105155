import { Injectable } from '@angular/core';
import { Http, Headers, Request, RequestMethod, RequestOptions } from '@angular/http';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class GradeService {
  baseUrl = '';
  constructor(private configService: ConfigService, private http: Http) { 
    this.baseUrl = this.configService.getApiURI();
  }

  getPageData(){    
    let body = JSON.stringify({ });

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.baseUrl + "/candidates/GetGradePage", body, options);
  }

  saveData(data: any){
    let CandidateId = data.CandidateId;
    let Name = data.Name;
    let DocumentType = data.DocumentType;
    let Document = data.Document;
    let Email = data.Email;
    let Phone = data.Phone;
    let Address = data.Address;

    let body = JSON.stringify({ CandidateId, Name, DocumentType, Document, Email, Phone, Address });

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.baseUrl + "/candidates", body, options);
  }
}
