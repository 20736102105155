import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ProgramsComponent } from './components/programs/programs.component';
import { ProgramComponent } from './components/program/program.component';
import { MisionComponent } from './components/mision/mision.component';
import { VisionComponent } from './components/vision/vision.component';
import { ObjectivesComponent } from './components/objectives/objectives.component';
import { DirectoryComponent } from './components/directory/directory.component';
import { PorQueCtcComponent } from './components/por-que-ctc/por-que-ctc.component';
import { PreinscripcionComponent } from './components/preinscripcion/preinscripcion.component';
import { EmprendimientoComponent } from './components/emprendimiento/emprendimiento.component';
import { FormacionInglesComponent } from './components/formacion-ingles/formacion-ingles.component';
import { ConveniosComponent } from './components/convenios/convenios.component';
import { GaleriaComponent } from './components/galeria/galeria.component';
import { ActualizarDatosComponent } from './components/actualizar-datos/actualizar-datos.component';
import { ReglamentoComponent } from './components/reglamento/reglamento.component';
import { AccesoInformacionComponent } from './components/acceso-informacion/acceso-informacion.component';
import { CursosComponent } from './components/cursos/cursos.component';
import { CursoComponent } from './components/curso/curso.component';
import { InternacionalizacionComponent } from './components/internacionalizacion/internacionalizacion.component';
import { PqrsfComponent } from './components/pqrsf/pqrsf.component';
import { NoticiaComponent } from './components/noticia/noticia.component';
import { TerminosComponent } from './components/terminos/terminos.component';
import { AspiranteGradoComponent } from './components/aspirante-grado/aspirante-grado.component';
import { ContactGeneralComponent } from './components/contact-general/contact-general.component';

const APP_ROUTES: Routes = [
    {
      path: 'inicio',
      component: HomeComponent
    },
    {
      path: 'mision',
      component: MisionComponent
    },
    {
      path: 'vision',
      component: VisionComponent
    },
    {
      path: 'objetivos',
      component: ObjectivesComponent
    },
    {
      path: 'funcionarios',
      component: DirectoryComponent
    },
    {
      path: 'programas',
      component: ProgramsComponent
    },
    {
      path: 'programa/:id',
      component: ProgramComponent
    },
    {
      path: 'cursos',
      component: CursosComponent
    },
    {
      path: 'curso/:id',
      component: CursoComponent
    },
    {
      path: 'por-que-ctc',
      component: PorQueCtcComponent
    },
    {
      path: 'preinscripcion',
      component: PreinscripcionComponent
    },
    {
      path: 'internacionalizacion',
      component: InternacionalizacionComponent
    },
    {
      path: 'emprendimiento',
      component: EmprendimientoComponent
    },
    {
      path: 'galeria',
      component: GaleriaComponent
    },
    {
      path: 'actualizar-datos',
      component: ActualizarDatosComponent
    },
    {
      path: 'acceso-a-la-informacion',
      component: AccesoInformacionComponent
    },
    {
      path: 'reglamento',
      component: ReglamentoComponent
    },
    {
      path: 'pqrsf',
      component: PqrsfComponent
    },
    {
      path: 'noticia/:id',
      component: NoticiaComponent
    },
    {
      path: 'terminos',
      component: TerminosComponent
    },
    {
      path: 'aspirante-grado',
      component: AspiranteGradoComponent
    },
    {
      path: 'contacto',
      component: ContactGeneralComponent
    },
    {
      path: '**',
      pathMatch: 'full',
      redirectTo: '/inicio'
    }
  ];
export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { useHash: true })