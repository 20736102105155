import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { LocalStorageService } from './local-storage.service';
import { Http, Headers } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class DirectoryService {
  baseUrl: string = '';
  userProfile: any;
  constructor(private _configService: ConfigService, private _localStorage: LocalStorageService, private _http: Http) { 
    this.baseUrl = this._configService.getApiURI();
  }

  getAllFuncionarios() {
    this.userProfile = this._localStorage.getParsedObject("auth-data");
    let headers = new Headers();
    let body = JSON.stringify({ });
    headers.append('Content-Type', 'application/json');
    return this._http.get(this.baseUrl + '/funcionarios', { headers });
  }
}
