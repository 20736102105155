import { Component, OnInit } from '@angular/core';
import { PublicationService } from '../../services/publication.service';
declare var $: any;
declare var swal: any;
@Component({
  selector: 'app-acceso-informacion',
  templateUrl: './acceso-informacion.component.html',
  styleUrls: ['./acceso-informacion.component.css']
})
export class AccesoInformacionComponent implements OnInit {
  resolutionList: any[] = [];
  decreesList: any[] = [];
  circularList: any[] = [];
  planList: any[] = [];
  constructor(private _publicationService: PublicationService) {
    this.loadAllPublications();
   }

  ngOnInit() {
    $(document).ready(function(){
      $('.tabs').tabs();
    });
  }

  loadAllPublications(){
    this._publicationService.getAllPublications().subscribe((result)=>{
      let data = result.json().data;
      if(data){
        if(data.length > 0) {
          data.forEach((item)=>{
            if(item.type == 'Resoluciones') {
              this.resolutionList.push(item);
            }
            if(item.type == 'Decretos') {
              this.decreesList.push(item);
            }
            if(item.type == 'Circulares') {
              this.circularList.push(item);
            }
            if(item.type == 'Planes') {
              this.planList.push(item);
            }
          });
        }
      }
    },
    error => {
      if(error.status == 401){
      }
      if(error.json() || error.json().type == "error"){
        let errorMessage = "Ocurrió un error al ejecutar ésta acción. Por favor verifique";
        if(error.json().message != undefined){
          errorMessage = error.json().message;
        }
        swal('Señor Usuario', errorMessage, 'error');
      }
    });
  }

}
