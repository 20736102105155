import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preinscripcion',
  templateUrl: './preinscripcion.component.html',
  styleUrls: ['./preinscripcion.component.css']
})
export class PreinscripcionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
