import { Injectable } from '@angular/core';
import { Http, Headers, Request, RequestMethod, RequestOptions } from '@angular/http';
import { LocalStorageService } from './local-storage.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  baseUrl: string = '';
  public loggedIn = false;
  userProfile: any;
  tokenData: any;
  constructor(private _localStorage: LocalStorageService, private _configService: ConfigService, private _http: Http) { 
    this.loggedIn = !!this._localStorage.get('auth-data');
    this.baseUrl = this._configService.getApiURI();
  }

  getAllContacts() {
    this.userProfile = this._localStorage.getParsedObject("auth-data");
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this._http.get(this.baseUrl + '/contacts', { headers });
  }

  getContact(id: number) {
    this.userProfile = this._localStorage.getParsedObject("auth-data");
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this._http.get(this.baseUrl + '/contacts/' + id, { headers });
  }

  saveData(data: any){
    this.userProfile = this._localStorage.getParsedObject("auth-data");

    let ContactId = data.PqrdfId;
    let Name = data.Name;
    let LastName = data.LastName;
    let Email = data.Email;
    let Message = data.Message;
    let Active = 1;
    
    let body = JSON.stringify({ ContactId, Name, LastName, Email, Message, Active });

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this._http.post(this.baseUrl + "/contacts", body, options);
  }

  updateData(data: any){
    this.userProfile = this._localStorage.getParsedObject("auth-data");

    let ContactId = data.PqrdfId;
    let Name = data.Name;
    let LastName = data.LastName;
    let Email = data.Email;
    let Message = data.Message;
    let Active = 1;
    
    let body = JSON.stringify({ ContactId, Name, LastName, Email, Message, Active });

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this._http.put(this.baseUrl + "/contacts/" + ContactId, body, options);
  }

  deleteData(requestId: number){
    this.userProfile = this._localStorage.getParsedObject("auth-data");

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this._http.delete(this.baseUrl + '/contacts/'+ requestId, { headers });
  }
}